import {
  Context, createContext, Dispatch, SetStateAction,
} from 'react';

export interface UserData {
    userLoggedIn: { isLoggedIn: boolean, isAdmin: boolean, isLoaded: boolean, subscription: string };
    setUserLoggedIn: Dispatch<SetStateAction<{isLoggedIn: boolean; isAdmin?: boolean, isLoaded?: boolean, subscription?: string}>>;
}
// eslint-disable-next-line @typescript-eslint/no-empty-function
const UserContext: Context<UserData> = createContext(
  {
    userLoggedIn: {
      isLoggedIn: false, isAdmin: false, isLoaded: false, subscription: 'Inactive',
    },
    setUserLoggedIn: (setData) => ({}),
  } as UserData,
);
export default UserContext;
