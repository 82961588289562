import { getItem, setItem, removeItem } from './safeLocalStorage';

const authTokenKey = 'token';

export default class AuthTokenStore {
  public static save(token: string) {
    return setItem(authTokenKey, token);
  }

  public static get() {
    return getItem(authTokenKey);
  }

  public static clear() {
    return removeItem(authTokenKey);
  }
}
