import { gql } from '@apollo/client';

export const DUPLICATE_AS_NEW = gql`mutation DuplicateProductInstanceAsNew($data: DuplicateProductInstanceAsNewInput!) {
    duplicateProductInstanceAsNew(data: $data) {
        newProductInstance {
            id
            productName
            projectId
        }
        originalProductInstance {
            id
            productName
            projectId
        }
    }
}`;

export const CREATE_PRODUCT_INSTANCE = gql`mutation CreateProductInstance($data: CreateProductInstanceInput!) {
    createProductInstance(data: $data) {
        id
        productId
        productName
        projectId
        description
        configuration
        shadedView
    }
}`;

export const GET_PRODUCT_INSTANCES_BY_ID = gql`query GetProductInstancesById($data: [String!]!) {
    getProductInstancesById(data: $data) {
        id
        projectId
    }
}`;

export const GET_PRODUCT_INSTANCE_METADATA = gql`query GetProductInstanceMetadata($data: GetProductDataByInstanceIdInput!) {
    getProductDataByInstanceId(data: $data) {
        id
        metadata
    }
}`;

export const SAVE_PRODUCT_CONFIGURATION = gql`mutation SaveProductInstanceConfiguration($data: SaveProductInstanceConfigurationInput!) {
    saveProductInstanceConfiguration(data: $data) {
        id
    }
}`;

export const GET_USER_PROJECTS = gql`query GetUserProjects {
    getUserProjects {
        id
        projectName
        projectDescription
        projectReference
    }
}`;

export const CREATE_PROJECT = gql`mutation CreateProject($data: CreateProjectInput!) {
      createProject(data: $data) {
          id
      }
  }`;

export const GET_PRODUCT_DATA = gql`
    query (
        $getProductType: GetProductInput!
        $getMaterialOptions: GetMaterialOptionsByProductIdInput!
        $productId: String!
    ) {
        getProductById(data: $getProductType) {
            id
            productName
            productCode
            description
            shadedView
            documentId
            versionId
            versionName
            elementId
        }
        getProductSettings(productId: $productId) {
            productSettings
        }
        getProductRules(productId: $productId) {
            id
            rule
        }
        getMaterialOptionsByProductId(data: $getMaterialOptions) {
            id
            productId
            variantOptions
        }
    }
`;

export const GET_SHADED_VIEW_URL = gql`query($data: GetShadedViewUrlInput!){
  getProductShadedViewUrl(data: $data){
    url
  }
}`;

export const GET_TESSELLATED_FACES = gql`query($tessellatedInput: GetTessellatedInput!){
  getTessellatedFaces(tessellatedInput: $tessellatedInput){
    tessellatedFaces
  }
}`;
