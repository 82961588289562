import {
  useState, useContext, useEffect, useCallback,
} from 'react';
import Head from 'next/head';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { Header } from 'fjs-components';
import { getCADTool } from '@future-joinery-systems/fjs-cad';
import { useLazyQuery, useMutation } from '@apollo/client';
import Router from 'next/router';
import { window } from 'rxjs/operators';
import * as Sentry from '@sentry/nextjs';
import { throttle } from 'lodash';
import PriceDataContext from './helpers/contexts/PriceDataContext';
import useUser from '../lib/useUser';
import UserContext from './helpers/contexts/UserContext';
import ProjectContext from './helpers/contexts/ProjectContext';
import {
  DUPLICATE_AS_NEW,
  GET_PRODUCT_INSTANCES_BY_ID,
  GET_PRODUCT_INSTANCE_METADATA,
  CREATE_PROJECT, GET_USER_PROJECTS, CREATE_PRODUCT_INSTANCE,
} from '../lib/sharedGQL';
import useGlobalState from './hooks/globalState';

const pagesLoggedIn = [
  { name: 'My Products', link: '/products', id: Math.floor(Math.random() * 10e5) },
  { name: 'Catalogue', link: '/catalogue', id: Math.floor(Math.random() * 10e5) },
  { name: 'Logout', link: '/logout', id: Math.floor(Math.random() * 10e5) },
  {
    name: 'Give Feedback', link: 'https://coda.io/form/FJS-Configurator-Feedback_dlL4IZglHye', id: Math.floor(Math.random() * 10e5), openInNewTab: true,
  },
];
const pagesLoggedOut = [
  { name: 'Home', link: '/', id: Math.floor(Math.random() * 10e5) },
  { name: 'Login', link: '/login', id: Math.floor(Math.random() * 10e5) },
];

function Layout({
  children, productsInSketchup, selectedProducts, appStatus, versionName, watchModel,
}) {
  const [globalPriceData, setGlobalPriceData] = useState({});

  // TODO: why are we using 2 different ways of getting the user? was the first line before we used globalState?
  const { user } = useUser();
  const userContext = useContext(UserContext);

  const [getUserProjects] = useLazyQuery(GET_USER_PROJECTS, { fetchPolicy: 'network-only' });
  const [createNewProject, { loading: createProjectLoading }] = useMutation(CREATE_PROJECT);
  const [project, setProject] = useState({ id: '' });
  const [checkingProducts, setCheckingProducts] = useState(false);

  // TODO: why do we use contexts rather than another key on global state for price and user?
  const [showDuplicateModal, setShowDuplicateModal] = useGlobalState('showDuplicateModal');

  const [duplicateProductInstanceAsNew] = useMutation(DUPLICATE_AS_NEW, {
    onCompleted: (response) => {
      console.log('duplicateProductInstanceAsNew complete', response);
    },
  });

  const [createProductInstance] = useMutation(CREATE_PRODUCT_INSTANCE, {
    onCompleted: (response) => {
      console.log('createProductInstance complete', response);
    },
  });

  const [getProductInstanceMetadata] = useLazyQuery(GET_PRODUCT_INSTANCE_METADATA, {
    onCompleted: (response) => {
      console.log('Got metadata from instance id', response);
    },
  });
  const [getProductInstancesById] = useLazyQuery(GET_PRODUCT_INSTANCES_BY_ID, {
    onCompleted: (response) => {
      console.log('Checked productsInSketchup against product instances in db', response);
    },
  });

  async function retrieveMetadataAndFlagInSKP(existingProductInSKP, cadTool): Promise<string> {
    console.log('Layout/retrieveProductDataAndSaveToComponent: retrieving product data for instance', existingProductInSKP?.id);
    if (window === undefined) return null;
    if (!project?.id) return null;
    if (!existingProductInSKP?.id) return null;

    const metadataResult = await getProductInstanceMetadata({
      variables: {
        data: {
          productInstanceId: existingProductInSKP.id,
        },
      },
    });
    if (metadataResult.error) {
      console.log(`Error retrieving metadata for ${existingProductInSKP.name}.`, metadataResult.error);
      Sentry.captureException(metadataResult.error);
    }

    if (metadataResult.data?.getProductDataByInstanceId) {
      console.log('Metadata retrieved from DB', metadataResult);
      const metadataFlag = metadataResult.data.getProductDataByInstanceId.metadata !== undefined ? true : 'error';
      const updatedProductInstance = {
        ...existingProductInSKP,
        projectId: project.id,
        billOfMaterials: metadataFlag,
      };
      await cadTool.updateProduct(
        updatedProductInstance,
        null, // keep existing geometry
        existingProductInSKP.id,
      ).toPromise();
      return 'Success';
    }

    console.log('Metadata could not be retrieved', existingProductInSKP);
    return 'Failed';
  }

  async function retrieveMetadataSafely(processedIds, productInstancesToRetrieveMetadata, cadTool) {
    console.log('Retrieving metadata safely...', { processedIds, productInstancesToRetrieveMetadata });
    const uniqueProductIdsInBatch = [];
    const batch = [];
    productInstancesToRetrieveMetadata.filter((pi) => !processedIds.includes(pi.id)).forEach((pi) => {
      if (!uniqueProductIdsInBatch.includes(pi.productId)) {
        console.log('retrieveMetadataSafely: product not already in batch - processing instance in this round');
        uniqueProductIdsInBatch.push(pi.productId);
        batch.push(pi);
      } else {
        console.log('retrieveMetadataSafely: product already in batch - instance left until next round');
      }
    });
    await Promise.all(batch.map((prod) => retrieveMetadataAndFlagInSKP(prod, cadTool)));
    batch.forEach((pi) => processedIds.push(pi.id));
    console.log(`retrieveMetadataSafely: added ${batch.length} ids to processed`, processedIds);

    if (processedIds.length !== productInstancesToRetrieveMetadata.length) {
      console.log('retrieveMetadataSafely: let\'s go again');
      await retrieveMetadataSafely(processedIds, productInstancesToRetrieveMetadata, cadTool);
    }
  }

  useEffect(() => {
    if (userContext?.userLoggedIn && !userContext?.userLoggedIn.isLoggedIn && Router?.pathname && Router?.pathname !== '/login') {
      Router.push('/login');
    }
  });

  const throttledProjectCheck = useCallback(throttle(async () => {
    console.log('appStatus', appStatus);
    console.log('userContext', userContext);
    if (!userContext?.userLoggedIn?.isLoggedIn) return;

    if (!appStatus) return;
    console.log('Checking Project');

    const cadTool = getCADTool();
    const projectId = await cadTool.getFileMeta('projectId').toPromise();
    let savedProjectPath = appStatus?.project_path;
    const currentProjectPath = appStatus?.current_path;
    console.info('savedProjectPath', savedProjectPath);
    console.info('currentProjectPath', currentProjectPath);
    if (savedProjectPath === '' && currentProjectPath !== '') {
      await cadTool.setFileMeta('projectPath', currentProjectPath);
      savedProjectPath = currentProjectPath;
    }
    console.log('Project Details', {
      savedProjectPath, currentProjectPath, savedMatchesCurrent: currentProjectPath === savedProjectPath, projectId, project, createProjectLoading,
    });
    let shouldCreateNewProject = false;
    if (projectId) {
      // Check if project exists in DB and belongs to logged-in user
      const userProjectsData = await getUserProjects();
      if (userProjectsData.data?.getUserProjects?.length) {
        if (!userProjectsData.data.getUserProjects.map((proj) => proj.id).includes(projectId)) {
          console.log('Project does not belong to user, create new');
          shouldCreateNewProject = true;
        }
      }
      if (!shouldCreateNewProject && savedProjectPath === currentProjectPath) {
        console.log('Correct project, setting id');
        setProject({ id: projectId });
      } else if (savedProjectPath !== currentProjectPath) {
        console.log('Project path has changed, creating new project');
        shouldCreateNewProject = true;
      }
    } else if (project?.id.length) {
      console.log('Project already set, not setting again');
    } else {
      console.log('No project ID found, creating new project');
      shouldCreateNewProject = true;
    }
    if (!createProjectLoading && shouldCreateNewProject) {
      console.log('About to create new project', project.id);

      const newProject = await createNewProject({ variables: { data: {} } });
      console.log('NEW PROJECT ID');
      console.log(newProject.data.createProject);
      const idSet = await cadTool.setFileMeta('projectId', newProject.data.createProject.id).toPromise();
      const pathSet = await cadTool.setFileMeta('projectPath', currentProjectPath).toPromise();
      console.log('idSet', idSet);
      console.log('pathSet', pathSet);

      setProject(newProject.data.createProject);
    }
  }, 2000), [appStatus, userContext]);

  useEffect(() => {
    throttledProjectCheck();
  }, [appStatus, userContext]);

  const checkAndUpdateProducts = async () => {
    console.log('Executing Check and Update Products...');

    try {
      const cadTool = getCADTool();
      const productInstancesToSave = [];
      const productInstancesToDuplicate = [];
      const productInstancesToRetrieveMetadata = [];
      const productInstanceIdsInSkp = productsInSketchup.filter((p) => !p.id.includes('#')).map((prod) => prod.id);

      const productInstancesInDbData = await getProductInstancesById({
        variables: {
          data: productInstanceIdsInSkp,
        },
      });
      const productInstancesInDB = productInstancesInDbData.data?.getProductInstancesById;
      console.log('productInstanceIdsInDB', productInstancesInDB);

      let i = 1;
      for (const prodInst of productsInSketchup) {
        console.log(`CHECKING PRODUCT INSTANCE ${i} of ${productsInSketchup.length}: ${prodInst.id}, ${prodInst.productName}`);
        // check if product instance is in database
        if (!productInstancesInDB.map((pi) => pi.id).includes(prodInst.id)) {
          // check if id has been affected by make unique
          // SP - not sure if this is guaranteed to work every time? Should be? even if duplicated from a duplicate, this will slice at the first '#'
          if (prodInst.id.includes('#') && productInstancesInDB.map((pi) => pi.id).includes(prodInst.id.slice(0, prodInst.id.indexOf('#')))) {
            productInstancesToDuplicate.push(prodInst);
            console.log(`Instance has been made unique and needs duplicating in the database - ${prodInst.id}, ${prodInst.productName}`);
          } else {
            productInstancesToSave.push(prodInst);
            console.log(`Instance not found in database and needs to be saved as a new instance in the database - ${prodInst.id}, ${prodInst.productName}`);
          }
        } else {
          // check if current projectId matches the projectId of this instance in the database
          // eslint-disable-next-line no-lonely-if
          if (!prodInst.projectId) {
            productInstancesToDuplicate.push(prodInst);
            console.log(`Instance has no project and needs duplicating with current project id - ${prodInst.id}, ${prodInst.productName}`);
          } else if (prodInst.projectId !== project.id) {
            productInstancesToDuplicate.push(prodInst);
            console.log(`Instance belongs to a different project and needs duplicating with current project id - ${prodInst.id}, ${prodInst.productName}`);
          } else {
            // check for metadata
            // eslint-disable-next-line no-lonely-if
            if (prodInst.billOfMaterials !== true) {
              productInstancesToRetrieveMetadata.push(prodInst);
              console.log(`Instance has no metadata, needs to be retrieved - ${prodInst.id}, ${prodInst.productName}`);
            } else {
              console.log(`Instance is good to go! - ${prodInst.id}, ${prodInst.productName}`);
            }
          }
        }
        i++;
      }

      console.log('To Save:         ', productInstancesToSave.length);
      console.log('To Duplicate:    ', productInstancesToDuplicate.length);
      console.log('To Get Metadata: ', productInstancesToRetrieveMetadata.length);

      // If there are any products to save, save them
      if (productInstancesToSave.length) {
        const savePromises = [];
        for (const prodInst of productInstancesToSave) {
          savePromises.push(async () => {
            const savedProdInstResult = await createProductInstance({
              variables: {
                data: {
                  productId: prodInst.productId,
                  projectId: project.id,
                  configuration: prodInst.configuration,
                  shadedView: prodInst.shadedView,
                },
              },
            });
            if (savedProdInstResult.data.createProductInstance) {
              const savedInstance = savedProdInstResult.data.createProductInstance;
              await cadTool.updateProduct({
                ...savedInstance, billOfMaterials: false,
              }, null, prodInst.id).toPromise();
              console.log('INSTANCE SAVED CORRECTLY', {
                oldId: prodInst.id, newId: savedInstance.id, productName: savedInstance.productName, result: savedProdInstResult.data,
              });
            } else {
              console.log('INSTANCE DID NOT SAVE CORRECTLY', { oldId: prodInst.id, productName: prodInst.productName, result: savedProdInstResult.data });
            }
          });
        }

        console.log(`SAVING ${productInstancesToSave.length} PRODUCTS`);
        await Promise.all(savePromises.map((p) => p()));
      }

      // If there are any products to duplicate, duplicate them
      if (productInstancesToDuplicate.length) {
        const duplicatePromises = [];
        for (const prodInst of productInstancesToDuplicate) {
          duplicatePromises.push(async () => {
            // if the instance has come from make unique, then parse the id for the original to duplicate
            const idNoHash = prodInst.id.includes('#') ? prodInst.id.slice(0, prodInst.id.indexOf('#')) : prodInst.id;
            const duplicatedProdInstResult = await duplicateProductInstanceAsNew({
              variables: {
                data: {
                  productInstanceId: idNoHash,
                  projectId: project.id,
                  // if the instance in sketchup has an incorrect or false flag, then reset the metadata of the duplicate in the database
                  resetMetadata: prodInst.billOfMaterials !== true,
                },
              },
            });
            if (duplicatedProdInstResult.data.duplicateProductInstanceAsNew) {
              const duplicateInstanceId = duplicatedProdInstResult.data.duplicateProductInstanceAsNew.newProductInstance.id;
              await cadTool.updateProduct({
                ...prodInst, id: duplicateInstanceId, projectId: project.id, billOfMaterials: prodInst.billOfMaterials === true,
              }, null, prodInst.id).toPromise();
              console.log('INSTANCE DUPLICATED CORRECTLY', {
                oldId: prodInst.id, newId: duplicateInstanceId, productName: prodInst.productName, result: duplicatedProdInstResult.data,
              });
            } else {
              console.log('INSTANCE DID NOT DUPLICATE CORRECTLY', { oldId: prodInst.id, productName: prodInst.productName, result: duplicatedProdInstResult.data });
            }
          });
        }

        console.log(`DUPLICATING ${productInstancesToDuplicate.length} PRODUCTS`);
        await Promise.all(duplicatePromises.map((p) => p()));
      }

      // If there are any products to retrieve metadata for, retrieve it, then we're done
      if (productInstancesToRetrieveMetadata.length) {
        console.log('AAA: productsToRetrieveMetadata', productInstancesToRetrieveMetadata);
        await retrieveMetadataSafely([], productInstancesToRetrieveMetadata, cadTool);
        // await Promise.all(productInstancesToRetrieveMetadata.map((prod) => retrieveMetadataAndFlagInSKP(prod, cadTool)));
      }

      setCheckingProducts(false);
    } catch (error) {
      console.error('Error when executing Check and Update Products', error);
      // Sentry.captureException(error);
    }
  };

  useEffect(() => {
    (async () => {
      console.log('UE: checking products set', checkingProducts);
      if (checkingProducts) {
        await checkAndUpdateProducts();
      }
    })();
  }, [checkingProducts]);

  useEffect(() => {
    console.log('UE: productsInSketchup, project', productsInSketchup, project, appStatus);
    if (!project?.id || (appStatus && appStatus.current_path !== appStatus.project_path)) return;

    if (!checkingProducts) {
      setCheckingProducts(true);
    }
  }, [productsInSketchup, project, appStatus]);

  useEffect(() => {
    console.log('UE: selectedProducts', selectedProducts);
    if (selectedProducts && selectedProducts.length === 1 && showDuplicateModal) {
      setShowDuplicateModal(false);
    }
  }, [selectedProducts]);

  // console.log('Layout: productsInSketchup', productsInSketchup);
  // console.log('Layout: project', project);

  // if (checkingProducts) {
  //   return (
  //     <div>
  //       <Head>
  //         <title>FJS Configurator</title>
  //         <link rel="preconnect" href="https://fonts.googleapis.com" />
  //         <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
  //         <link
  //           href="https://fonts.googleapis.com/css2?family=Arvo:wght@400;600&display=swap"
  //           rel="stylesheet"
  //         />
  //         <link
  //           href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap"
  //           rel="stylesheet"
  //         />
  //       </Head>
  //       <div className="flex flex-col min-h-screen">
  //         <div className="flex-grow bg-fjsLightGrey">
  //           Checking Products...
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div>
      <Head>
        <title>FJS Configurator</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link
          href="https://fonts.googleapis.com/css2?family=Arvo:wght@400;600&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap"
          rel="stylesheet"
        />
      </Head>
      { user && <Header homeIcon size="Small" bgColour="LightGrey" pages={userContext.userLoggedIn?.isLoggedIn ? pagesLoggedIn : pagesLoggedOut} />}
      <PriceDataContext.Provider value={{ globalPriceData, setGlobalPriceData }}>
        <ProjectContext.Provider value={project}>
          <main className="flex flex-col min-h-screen">
            <div className="flex-grow bg-fjsLightGrey">
              {children}
            </div>
          </main>
        </ProjectContext.Provider>
      </PriceDataContext.Provider>
      <footer className="fixed bottom-0 w-full bg-fjsBlue h-9 text-fjsLightBlue text-xs flex justify-center items-center z-20 px-4">
        <Link target="_blank" href="https://www.joinerysystems.com/">www.joinerysystems.com</Link>
        <div className="absolute right-4 tracking-widest">{versionName}</div>
      </footer>
    </div>
  );
}

export default Layout;

Layout.propTypes = {
  children: PropTypes.node,
};
