import { createGlobalState } from 'react-hooks-global-state';

type GlobalStateType = {
  showDuplicateModal: boolean;
  cadTool?: string
};

const { useGlobalState } = createGlobalState<GlobalStateType>({
  showDuplicateModal: false,
  cadTool: null,
});

export default useGlobalState;
