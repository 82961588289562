import { getCADTool } from '@future-joinery-systems/fjs-cad';

export async function getFromSketchupStorage(key: string): Promise<string> {
  const CADTool = getCADTool();
  return ((CADTool.getToolMeta(key).toPromise()));
}

export function setInSketchupStorage(key: string, value: any) {
  const CADTool = getCADTool();
  try {
    if (CADTool) {
      CADTool.setToolMeta(key, value);
    }
  } catch (error) {
    console.error('FAILED TO SET IN SKETCHUP STORAGE', key, value);
  }
}

export function getCadToolName(): string {
  const CADTool = getCADTool();
  let CADToolName;
  // eslint-disable-next-line no-return-assign
  CADTool.name().subscribe((name) => CADToolName = name);
  return CADToolName;
}

export function getUserIdFromSketchupStorage(): Promise<string> {
  return getFromSketchupStorage('userId');
}

export function getUserObjectFromSketchupStorage(): Promise<string> {
  return getFromSketchupStorage('user');
}

export function getTokenFromSketchupStorage(): Promise<string> {
  return getFromSketchupStorage('token');
}

export function getAdminFlagFromSketchupStorage(): Promise<string> {
  return getFromSketchupStorage('isAdmin');
}

export function getSubscriptionFromSketchupStorage(): Promise<string> {
  return getFromSketchupStorage('subscription');
}
