/* eslint-disable react/jsx-props-no-spreading */
import { SWRConfig } from 'swr';
import { ApolloProvider } from '@apollo/client';
import { GlobalStylesProvider } from 'fjs-pigeonhole/dist/module';
import { window } from 'rxjs/operators';
import { useEffect, useState } from 'react';
import { getCADTool, IProductInstance, IProductInstanceOccurrence } from '@future-joinery-systems/fjs-cad';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/nextjs';
import { Thing } from 'fjs-components';
import UserContext from '../components/helpers/contexts/UserContext';
import fetch from '../lib/fetchJson';
import InitApolloClient from '../lib/clientApi';
import '../components/styles/styles.css';
import '../components/styles/scrollbarStyles.css';
import 'fjs-pigeonhole/dist/common/index.css';
import Layout from '../components/Layout';
import PluginUpdateNeededPage from '../components/PluginUpdateNeededPage';
import useGlobalState from '../components/hooks/globalState';
import useUser from '../lib/useUser';

LogRocket.init(`zk1wrb/configurator-${process.env.NEXT_PUBLIC_ENV_NAME || process.env.NODE_ENV}`);
LogRocket.getSessionURL((sessionURL) => {
  Sentry.configureScope((scope) => {
    scope.setExtra('sessionURL', sessionURL);
  });
});

const useGetPluginVersion = () => {
  const [state, setState] = useState<string>();

  useEffect(() => {
    (async () => {
      if (window === undefined) return () => null;
      const cadTool = getCADTool();
      const version = await cadTool.getToolMeta('version').toPromise();
      setState(version);
      console.log('Version: ', version);
      return () => console.log('Version:', state);
    })();
  }, []);
  return state;
};

const useWatchProducts = () => {
  const [state, setState] = useState<IProductInstance[]>([]);

  useEffect(() => {
    if (window !== undefined) {
      const cadTool = getCADTool();
      const observable = cadTool.watchProducts();
      const sub = observable.subscribe(setState);
      return () => sub.unsubscribe();
    }
    return () => null;
  }, []);
  return state;
};

const useSelectedProducts = () => {
  const [state, setState] = useState<IProductInstanceOccurrence[]>();

  useEffect(() => {
    if (window === undefined) return () => null;
    const cadTool = getCADTool();
    const observable = cadTool.watchSelectedOccurrences();
    const sub = observable.subscribe(setState);
    return () => sub.unsubscribe();
  }, []);
  return state;
};

const useAppStatus = (pluginUpdateNeeded) => {
  const [state, setState] = useState();

  useEffect(() => {
    if (window === undefined) return () => null;
    if (pluginUpdateNeeded) return () => null;
    const cadTool = getCADTool();
    const observable = cadTool.watchApp();
    const sub = observable.subscribe(setState);
    return () => sub.unsubscribe();
  }, [pluginUpdateNeeded]);
  return state;
};

const useWatchModel = () => {
  const [state, setState] = useState();

  useEffect(() => {
    if (window === undefined) return null;
    const cadTool = getCADTool();
    const observable = cadTool.watchModel();
    const sub = observable.subscribe(setState);
    return () => sub.unsubscribe();
  }, []);
  return state;
};

const checkPluginUpdateNeeded = (currentVersionString, minimumVersionString) => {
  if (!currentVersionString || !minimumVersionString) return true;

  const currentVersionArray = currentVersionString.split('.');
  const minimumVersionArray = minimumVersionString.split('.');

  if (currentVersionArray[0] < minimumVersionArray[0]) return true;
  if (currentVersionArray[0] > minimumVersionArray[0]) return false;
  if (currentVersionArray[1] < minimumVersionArray[1]) return true;
  if (currentVersionArray[1] > minimumVersionArray[1]) return false;
  return currentVersionArray[2] < minimumVersionArray[2];
};

function MyApp({
  Component,
  pageProps,
  err,
}) {
  const minimumPluginVersion = '0.3.8';

  const client = InitApolloClient();
  const [userLoggedIn, setUserLoggedIn] = useState({
    isLoggedIn: false, isAdmin: false, isLoaded: false, subscription: 'Inactive',
  });
  const [versionName, setVersionName] = useState<string>('0.0.0');
  const pluginUpdateNeeded = checkPluginUpdateNeeded(versionName, minimumPluginVersion);
  const productsInSketchup = useWatchProducts().filter((prod) => prod.occurrences.length);
  console.log('$$$$ -- APP productsInSketchup', productsInSketchup);
  const selectedProducts = useSelectedProducts();
  const appStatus = useAppStatus(pluginUpdateNeeded);
  // const [cadTool, setCadTool] = useState<string>(null);
  const [cadTool, setCadTool] = useGlobalState('cadTool');
  useEffect(() => {
    (async function () {
      if (window === undefined) return;

      const cadToolClass = getCADTool();
      const tool = await cadToolClass.name().toPromise();
      setCadTool(tool || 'browser');
      const version = await cadToolClass.getToolMeta('version').toPromise();
      setVersionName(version);
      if (tool !== 'Sketchup') {
        setVersionName('0.3.8');
      }
    }());
  }, []);
  const watchModel = useWatchModel();
  const newProps = { ...pageProps, productsInSketchup, selectedProducts };

  // return <div>test</div>;

  return (

    <GlobalStylesProvider>
      <ApolloProvider client={client}>
        <SWRConfig
          value={{
            fetcher: fetch,
            onError: (error) => {
              console.error(error);
            },
          }}
        >
          <UserContext.Provider value={{ userLoggedIn, setUserLoggedIn }}>
            {versionName && pluginUpdateNeeded && <PluginUpdateNeededPage versionName={versionName} />}
            {(versionName && cadTool !== '') && !pluginUpdateNeeded && (
            <Layout
              productsInSketchup={productsInSketchup}
              selectedProducts={selectedProducts}
              appStatus={appStatus}
              watchModel={watchModel}
              versionName={versionName}
            >
              {/* <Thing /> */}
              <Component {...newProps} err={err} />
            </Layout>
            )}
          </UserContext.Provider>
        </SWRConfig>
      </ApolloProvider>
    </GlobalStylesProvider>

  );
}

export default MyApp;
