import Head from 'next/head';
import Link from 'next/link';
import { LinkButton } from './Buttons';

const PluginUpdateNeededPage = ({ versionName }) => (
  <div>
    <Head>
      <title>FJS Configurator</title>
    </Head>

    <main className="flex flex-col min-h-screen">
      <div className="flex-grow bg-fjsLightGrey flex flex-col items-center justify-center text-center">
        <div className="max-w-md mb-12 px-10">
          <h1 className="mb-6">Welcome back!</h1>
          <h3 className="mb-12 text-lg">Looks like the FJS team have been busy - you&apos;ll need a copy of the latest plugin to continue:</h3>
          <LinkButton text="Go to Download Page" href="https://www.joinerysystems.com/beta/pJPNY9nqE88sm9AAe4EFK3b6uTU3QoWNuLCBggYsg7kb" newTab />
          <p className="mt-12 leading-relaxed text-sm">Already downloaded the plugin? You can install it in the SketchUp Extension Manager, then restart SketchUp to get going.</p>
        </div>
      </div>
    </main>

    <footer className="fixed bottom-0 w-full bg-fjsBlue h-9 text-fjsLightBlue text-xs flex justify-center items-center z-20 px-4">
      <Link href="https://www.joinerysystems.com/" target="_blank">www.joinerysystems.com</Link>
      <div className="absolute right-4 tracking-widest">{versionName}</div>
    </footer>
  </div>
);

export default PluginUpdateNeededPage;
