import Link from 'next/link';

const buttonStyle = 'py-2 px-4 font-display font-medium rounded-md border-0 transition-transform duration-300 ease-in-out hover:scale-105 focus:outline-none';
const buttonColours = { green: 'text-fjsWhite bg-fjsGreen', blue: 'text-fjsWhite bg-fjsBlue', white: 'text-fjsMidGrey bg-fjsWhite' };

export const LinkButton = ({
  text = 'Go Somewhere',
  href,
  colour = 'green',
  newTab = false,
}) => {
  if (newTab) {
    return (
      <Link target="_blank" href={href} className={`${buttonStyle} ${buttonColours[colour]}`} passHref>{text}</Link>
    );
  }

  return (
    <Link href={href}>
      <button type="button" className={`${buttonStyle} ${buttonColours[colour]}`}>{text}</button>
    </Link>
  );
};

export const FunctionButton = ({
  text = 'Do Something',
  onClick,
  colour = 'green',
  disabled = false,
}) => (
  <button type="button" disabled={disabled} onClick={onClick} className={`button-${colour}`}>{text}</button>
);

export const FormButton = ({
  text = 'Submit Something',
  colour = 'green',
}) => (
  <button type="submit" className={`${buttonStyle} ${buttonColours[colour]}`}>{text}</button>
);

export const FancyButton = ({
  children,
  onClick,
  small = false,
  disabled = false,
}) => (
  <div className={`flex content-start justify-center relative ${small ? 'h-9 w-9' : 'h-12 w-12'}`}>
    <div className={`absolute rounded-full bg-fjsMidGrey ${small ? 'h-8 w-8' : 'h-10 w-10'}`} />
    <button
      disabled={disabled}
      type="button"
      onClick={onClick}
      className={
        `flex justify-center items-center absolute rounded-full ${disabled ? buttonColours.white : buttonColours.green} ${small ? 'h-8 w-8' : 'h-10 w-10'} cursor-${disabled ? 'default' : 'pointer'} translate-x-1 -translate-y-1 ${!disabled && 'transition duration-200 ease-in-out active:translate-x-px active:-translate-y-px'}`
    }
    >
      {children}
    </button>
  </div>
);

export const SyncButton = ({
  onClick,
}) => (
  <FancyButton onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" viewBox="0 0 16 16">
      <path
        d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"
      />
      <path
        fillRule="evenodd"
        d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
      />
    </svg>
  </FancyButton>
);

export const DeleteButton = ({
  onClick,
}) => (
  <FancyButton small onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
      <path
        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
      />
      <path
        fillRule="evenodd"
        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
      />
    </svg>
  </FancyButton>
);

export const DuplicateButton = ({
  onClick,
}) => (
  <FancyButton small onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
    </svg>
  </FancyButton>
);

export const LoadingIcon = ({ className }) => (
  <svg
    className={`${className} animate-spin h-5 w-5`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
);
