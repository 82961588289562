// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { excludeGraphQLFetch } from 'apollo-link-sentry';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  // BDF
  // dsn: SENTRY_DSN || 'https://5fa6bdcd49994bd298110dc5d923cdc8@o1120001.ingest.sentry.io/6155021',
  // FJS
  dsn: SENTRY_DSN || 'https://091624299ef44cd9b2d4618c735eb508@o1157255.ingest.sentry.io/6239564',
  // FJS BF
  // dsn: SENTRY_DSN || 'https://eddf2374b5d044f6b1f2f22968e81f19@o1158887.ingest.sentry.io/6254230',
  environment: process.env.NEXT_PUBLIC_ENV_NAME || process.env.NODE_ENV,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampler: (samplingContext) => {
    if (samplingContext.transactionContext.name.search('error')) {
      // These are important - take a big sample
      return 1;
    }
    if (samplingContext.transactionContext.status?.search('error')) {
      return 1;
    }
    if (samplingContext.request?.headers?.host?.search('localhost')) {
      return 0;
    }
    // Default sample rate
    return 0.01;
  },
  beforeBreadcrumb: excludeGraphQLFetch,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
