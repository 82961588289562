import {
  HttpLink, InMemoryCache, ApolloClient, NormalizedCacheObject, from,
} from '@apollo/client';
import { persistCache } from 'apollo-cache-persist';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { SentryLink } from 'apollo-link-sentry';
import * as Sentry from '@sentry/nextjs';
import { some } from 'lodash';
import AuthTokenStore from './authTokenStore';
import { getCadToolName, getFromSketchupStorage, getTokenFromSketchupStorage } from './sketchupStorage';

function InitApolloClient(): ApolloClient<NormalizedCacheObject> {
  const cadToolName = typeof window !== 'undefined' ? getCadToolName() : '';
  const uri = `${process.env.NEXT_PUBLIC_APP_URL}/graphql`;
  const ssrMode = typeof window === 'undefined';
  const httpLink = new HttpLink({
    uri,
  });

  let token;
  const authObj = (authToken) => ({
    headers: {
      authorization: `Bearer ${authToken}`,
    },
  });
  const withToken = setContext(() => {
    // if you have a cached value, return it immediately
    if (token) {
      console.log('cached token', token);
      return authObj(token);
    }

    if (!ssrMode && cadToolName !== 'Sketchup') {
      return authObj(AuthTokenStore.get());
    }

    return getFromSketchupStorage('token').then((userToken) => {
      console.log('userToken', userToken);
      if (userToken?.includes('ey')) {
        token = userToken;
        return authObj(userToken);
      }
      console.log('HERE');
      return getTokenFromSketchupStorage().then((userToken2) => {
        console.log('userToken2', userToken2);
        if (userToken2?.includes('ey')) {
          token = userToken2;
          return authObj(userToken2);
        }
        return authObj(AuthTokenStore.get());
      });
    });
  });

  const sentryLink = new SentryLink({
    uri,
    // attachBreadcrumbs: {
    //   includeQuery: true,
    //   includeVariables: true,
    //   includeError: true,
    // },
  });

  const resetToken = onError(({ networkError, graphQLErrors }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (networkError && networkError.name === 'ServerError' && networkError?.statusCode === 401) {
      // remove cached token on 401 from the server
      token = null;
    }

    if (networkError && Array.isArray(networkError) && some(networkError, (error) => error.name.includes('Authentication token'))) {
      token = null;
    }
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.warn(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
        Sentry.captureMessage(message);
      });
    }
  });

  const cache = new InMemoryCache({
    addTypename: false,
  });

  if (!ssrMode) { // if on client machine
    persistCache({
      cache: cache as any,
      storage: window?.localStorage,
    });
  }

  return new ApolloClient({
    link: from([withToken, resetToken, sentryLink, httpLink]),
    cache,
    defaultOptions: {
      query: { errorPolicy: 'all', fetchPolicy: 'cache-first' },
    },
  });
}

export default InitApolloClient;
